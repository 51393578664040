import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

//https://react-svgr.com/playground/
//https://material-ui.com/api/svg-icon/
function EntertainmentIcon(props) {
    return (
      <SvgIcon 
        viewBox="0 0 64 64"
        height={512}
        width={512}
        {...props}>
        
        <path d="M56 24h2v2h-2zM54 27h4v2h-4zM52 30h6v2h-6zM8.877 12.535l3.656-3.656 1.414 1.414-3.656 3.656zM5.878 9.536L9.534 5.88l1.414 1.414-3.656 3.656z" />
        <path d="M59 36a3.009 3.009 0 003-3V5a3.009 3.009 0 00-3-3H5a3.009 3.009 0 00-3 3v28a3.009 3.009 0 003 3h11.69a6.743 6.743 0 001.42 2H18a3.009 3.009 0 00-3 3v1h-4a3.009 3.009 0 00-3 3v2H5a3.009 3.009 0 00-3 3v11a1 1 0 001 1h6a1 1 0 001-1v-1h44v1a1 1 0 001 1h6a1 1 0 001-1V50a3.009 3.009 0 00-3-3h-3v-2a3.009 3.009 0 00-3-3h-4v-1a3.009 3.009 0 00-3-3h-.11a6.743 6.743 0 001.42-2zM8 60H4V50a1 1 0 011-1h3zm48-11h3a1 1 0 011 1v10h-4zM16.08 34H5a1 1 0 01-1-1V5a1 1 0 011-1h54a1 1 0 011 1v28a1 1 0 01-1 1H47.92a6.634 6.634 0 00.08-1 7 7 0 00-14 0 6.634 6.634 0 00.08 1h-4.16a6.634 6.634 0 00.08-1 7 7 0 00-14 0 6.634 6.634 0 00.08 1zM28 38h-.11a6.743 6.743 0 001.42-2h5.38a6.743 6.743 0 001.42 2H36a3.009 3.009 0 00-3 3v1h-2v-1a3.009 3.009 0 00-3-3zm-10-5a5 5 0 115 5 5 5 0 01-5-5zm-1 8a1 1 0 011-1h10a1 1 0 011 1v1H17zm36 3a1 1 0 011 1v13H10V45a1 1 0 011-1zm-7-4a1 1 0 011 1v1H35v-1a1 1 0 011-1zm-5-2a5 5 0 115-5 5 5 0 01-5 5z" />
        <path d="M40.45 16.11l-12-6A1 1 0 0027 11v12a1 1 0 00.47.85A1.014 1.014 0 0028 24a1.03 1.03 0 00.45-.11l12-6a1 1 0 000-1.78zM29 21.38v-8.76L37.76 17z" />

      </SvgIcon>
    );
  }

  export default EntertainmentIcon;